<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="cancelDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12 pb-4>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
            >
              Do you want to cancel this deal ?
            </span>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#30B868"
              @click="cancelDeal"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="cancelDialogue = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start pt-4 px-2 px-lg-0 pl-lg-4 pl-xl-4>
      <v-flex xs12>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs7 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Order Details
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start px-2 pl-4 pl-xl-6>
      <v-flex xs12 lg11 xl11>
        <v-layout wrap justify-space-between>
          <v-flex xs9 sm8 xl10 text-left order-sm-1>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span
                  style="
                    font-family: opensansbold;
                    font-size: 20px;
                    color: #808080;
                  "
                >
                  {{ formatDate(order.init_date) }}
                </span>
              </v-flex>
              <v-flex xs12 sm9 md10 lg10 xl9 pr-2>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm6 text-left>
                    <span
                      style="
                        font-family: poppinslight;
                        font-size: 15px;
                        color: #1fb13b;
                      "
                    >
                      Order # {{ order.orderno }}
                    </span>
                  </v-flex>
                  <v-flex xs12 sm6 text-left text-sm-right>
                    <span
                      style="
                        font-family: poppinslight;
                        font-size: 15px;
                        color: #1fb13b;
                      "
                    >
                      Transaction Id : {{ order.transaction_id }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs3 sm2 xl1 order-sm-3 align-self-center>
            <v-sheet outlined color="#70707029" rounded>
              <v-card flat color="#F5F7FC" height="40px">
                <v-layout wrap justify-center fill-height>
                  <v-flex xs12 align-self-center>
                    <span
                      style="
                        font-family: opensansbold;
                        font-size: 18px;
                        color: #1fb13b;
                      "
                    >
                      PAID
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-sheet>
          </v-flex>
          <v-flex
            xs6
            sm2
            xl1
            px-sm-2
            order-sm-2
            align-self-center
            v-if="order.purchasetype != 'Normal' && order.cancancel"
          >
            <v-btn
              :ripple="false"
              depressed
              dark
              block
              tile
              color="#30B868"
              @click="cancelDialogue = true"
            >
              Cancel
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 pt-2>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex xs12 md8 lg8 xl8>
                <template v-for="(item, i) in orders">
                  <v-layout
                    wrap
                    justify-center
                    :key="i"
                    v-if="
                      $route.query.type == 'Cancelled' &&
                      (item.deliverystatus == 'Cancelled' ||
                        item.deliverystatus == 'Return')
                    "
                  >
                    <v-flex xs12 py-2>
                      <OrderItem
                        v-bind:storage="item"
                        v-bind:order="order"
                        :key="item.deliverystatus"
                        @stepper="winStepper"
                      />
                    </v-flex>
                    <v-flex xs12 py-2>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center :key="i" v-else>
                    <v-flex xs12 py-2>
                      <OrderItem
                        v-bind:storage="item"
                        v-bind:order="order"
                        :key="item.deliverystatus"
                        @stepper="winStepper"
                      />
                    </v-flex>
                    <v-flex xs12 py-2>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </template>
              </v-flex>
              <v-flex xs12 md4 lg4 xl4>
                <v-layout wrap justify-center>
                  <v-flex xs1 text-right hidden-sm-and-down>
                    <v-divider vertical> </v-divider>
                  </v-flex>
                  <v-flex xs12 md11>
                    <OrderDetails v-bind:storage="order" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import OrderItem from "./orderItem";
import OrderDetails from "./orderDetails";
export default {
  props: ["id"],
  components: {
    OrderItem,
    OrderDetails,
  },
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      orders: [],
      order: {},
      cancelDialogue: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchaseList/seller",
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: {
        //   orderid: this.$route.params.id,
        // },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.order = response.data.data;
            this.orders = response.data.data.details;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(item) {
      if (item.type == "cancell") this.cancelOrder(item);
    },
    cancelOrder(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/deliverystatus",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item.transactionid,
          productid: item.product.product._id,
          status: "Cancelled",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var defDate = new Date(item);
      defDate = defDate.toString().slice(0, 15);
      var today = new Date();
      today = today.toString().slice(0, 15);

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.toString().slice(0, 15);

      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;

      if (today == defDate) {
        return "Today";
      } else if (yesterday == defDate) {
        return "Yesterday";
      } else {
        return strTime;
      }
    },
  },
};
</script>
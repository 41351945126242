<template>
  <div>
    <PageLoader v-bind:storage="appLoading" v-bind:contain="true" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="cancelDialog" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
            >
              Do you want to cancel this product ?
            </span>
          </v-flex>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 14px;
              "
            >
              {{ storage.product.productname }}
            </span>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#30B868"
              @click="cancelProduct"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="cancelDialog = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex xs12 sm2 pr-1 py-1>
            <v-img
              :src="baseURL + '/u/' + storage.product.photos[0]"
              height="100px"
              contain
              :alt="storage.product.productname"
            >
              <template v-slot:placeholder> <ImageLoader /> </template>
            </v-img>
          </v-flex>
          <v-flex xs12 sm10>
            <v-layout wrap justify-center>
              <v-flex xs12 text-left>
                <router-link :to="'/Product/' + storage.product._id">
                  <span
                    style="
                      font-family: poppinslight;
                      font-size: 15px;
                      color: #000000;
                    "
                  >
                    {{ storage.product.productname }}
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs12 text-left pt-2>
                <v-layout wrap justify-space-between>
                  <v-flex xs2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 14px;
                            color: #8d8d8d;
                          "
                        >
                          Stock
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 18px;
                            color: #000000;
                          "
                        >
                          {{ storage.product.stockquantity }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 14px;
                            color: #8d8d8d;
                          "
                        >
                          Price
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 18px;
                            color: #000000;
                          "
                        >
                          {{ storage.product.price }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 14px;
                            color: #8d8d8d;
                          "
                        >
                          Offer
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 18px;
                            color: #000000;
                          "
                        >
                          {{ storage.product.offer }} %
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 14px;
                            color: #8d8d8d;
                          "
                        >
                          Size
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 18px;
                            color: #000000;
                          "
                        >
                          {{ storage.size }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 14px;
                            color: #8d8d8d;
                          "
                        >
                          Color
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <v-avatar :color="storage.color" size="25" />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 py-2 py-lg-0>
            <v-layout wrap justify-end>
              <v-flex
                xs6
                sm4
                md4
                lg3
                px-2
                v-if="
                  storage.deliverystatus != 'Cancelled' &&
                  storage.deliverystatus != 'Delivered' &&
                  order.purchasetype == 'Normal'
                "
              >
                <v-btn
                  :ripple="false"
                  depressed
                  dark
                  block
                  tile
                  color="#30B868"
                  @click="cancelDialog = true"
                >
                  Cancel
                </v-btn>
              </v-flex>
              <v-flex xs6 sm4 md4 lg3 px-2>
                <v-select
                  outlined
                  dense
                  color="#30B868"
                  placeholder="Order Status"
                  v-model="orderStatus"
                  :items="orderStatuses"
                  item-text="name"
                  item-value="value"
                  item-color="#30B868"
                  hide-details
                  v-on:input="changeStatus"
                  item-disabled="disabled"
                >
                  <template v-slot:item="{ item }">
                    <span class="text-left">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs6
                sm4
                md4
                lg3
                px-2
                v-if="
                  storage.deliverystatus == 'Placed' ||
                  storage.deliverystatus == 'Shipped' ||
                  storage.deliverystatus == 'In Transit' ||
                  storage.deliverystatus == 'Delivered'
                "
              >
                <v-sheet outlined color="#70707029" rounded>
                  <v-card
                    :href="
                      baseURL +
                      '/Purchase/printreceipt?id=' +
                      order._id +
                      '&productid=' +
                      storage.product._id
                    "
                    flat
                    color="#F5F7FC"
                    height="40px"
                  >
                    <v-layout wrap justify-center fill-height>
                      <v-flex xs12 align-self-center>
                        <span
                          style="
                            font-family: opensansbold;
                            font-size: 14px;
                            color: #1fb13b;
                          "
                        >
                          PRINT RECEIPT
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-sheet>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "order"],
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      orderStatus: "Placed",
      orderStatuses: [
        { name: "Placed", value: "Placed" },
        { name: "Cancelled", value: "Cancelled", disabled: true },
        { name: "Shipped", value: "Shipped" },
        { name: "In Transit", value: "In Transit" },
        { name: "Delivered", value: "Delivered" },
        { name: "Return", value: "Return" },
      ],
      cancelDialog: false,
    };
  },
  beforeMount() {
    this.orderStatus = this.storage.deliverystatus;
  },
  methods: {
    cancelProduct() {
      this.$emit("stepper", {
        type: "cancell",
        product: this.storage,
        transactionid: this.order._id,
      });
      this.cancelDialog = false;
    },
    changeStatus() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/deliverystatus",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.order._id,
          productid: this.storage.product._id,
          status: this.orderStatus,
          expecteddeliverydate: null,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.orders = response.data.data;
            this.storage.deliverystatus = this.orderStatus;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 md11 lg11 xl11>
        <v-layout wrap justify-center py-4 v-if="storage.deliveryaddress">
          <v-flex xs12 text-left>
            <span
              style="
                font-family: poppinsmedium;
                font-size: 15px;
                color: #000000;
              "
            >
              ADDRESS
            </span>
          </v-flex>
          <v-flex xs12 text-left pt-2>
            <span
              style="
                font-family: poppinssemibold;
                font-size: 14px;
                color: #000000;
              "
            >
              {{ storage.deliveryaddress.firstname }}
              {{ storage.deliveryaddress.lastname }}
            </span>
          </v-flex>
          <v-flex xs12 text-left pt-2>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              {{ storage.deliveryaddress.streetAddress }},
              {{ storage.deliveryaddress.locality }},
              {{ storage.deliveryaddress.city }},
              {{ storage.deliveryaddress.district }},
              {{ storage.deliveryaddress.state }},
              {{ storage.deliveryaddress.country }},
              {{ storage.deliveryaddress.pincode }}
            </span>
          </v-flex>
          <v-flex xs12 text-left pt-2>
            <v-layout wrap justify-center py-2>
              <v-flex xs1 align-self-center>
                <v-img
                  src="./../../../assets/icons/phone.svg"
                  height="15px"
                  contain
                  alt="Phone"
                ></v-img>
              </v-flex>
              <v-flex xs11 align-self-center>
                <span
                  v-if="storage.mobile"
                  style="
                    font-family: opensanssemibold;
                    font-size: 14px;
                    color: #474747;
                  "
                >
                  {{ storage.mobile }}
                </span>
                <span v-else>-</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center py-2>
              <v-flex xs1 align-self-center>
                <v-img
                  src="./../../../assets/icons/email.svg"
                  height="15px"
                  contain
                  alt="Email"
                ></v-img>
              </v-flex>
              <v-flex xs11 align-self-center>
                <span
                  v-if="storage.email"
                  style="
                    font-family: opensanssemibold;
                    font-size: 14px;
                    color: #474747;
                  "
                >
                  {{ storage.email }}
                </span>
                <span v-else>-</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex xs6 text-left>
            <span
              style="
                font-family: poppinsmedium;
                font-size: 15px;
                color: #000000;
              "
            >
              PRICE DETAILS
            </span>
          </v-flex>
          <v-flex xs6 text-right>
            <span
              style="font-family: poppinslight; font-size: 12px; color: #000000"
              v-if="storage.details"
            >
              {{ storage.details.length }} item<span
                v-if="storage.details.length > 1"
                >s
              </span>
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-4>
          <v-flex xs6 text-left>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Total MRP
            </span>
          </v-flex>
          <v-flex xs6 text-right>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Rs.
              <span v-if="storage.amount">
                {{ storage.amount }}
              </span>
              <span v-else>0</span>
            </span>
          </v-flex>
        </v-layout>
              <v-layout wrap justify-center pt-4>
          <v-flex xs6 text-left>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Delivery Charge
            </span>
          </v-flex>
          <v-flex xs6 text-right>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Rs.
              <span v-if="storage.shippingcharge">
                {{ (storage.shippingcharge).toFixed(2) }}
              </span>
              <span v-else>0</span>
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs6 text-left>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Discount Amount
            </span>
          </v-flex>
          <v-flex xs6 text-right>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #2cb194"
            >
              - Rs.
              <span v-if="storage.totaldiscount">
                {{ storage.totoaldiscount }}
              </span>
              <span v-else>0</span>
            </span>
          </v-flex>
        </v-layout>
         <v-layout
          wrap
          justify-center
          pt-2
          v-if="storage.isPromocodeApplied == true"
        >
          <v-flex xs12 sm6 text-left>
            <span
              style="font-family: opensansbold; font-size: 16px; color: green"
            >
              Promocode Applied
            </span>
          </v-flex>
          <v-flex xs12 sm6 text-right>
            <v-btn depressed ripple="false" color="red" flat tile small>
              <span
                style="font-family: opensansbold; font-size: 12px; color: white"
              >
                {{ storage.promoCodeDetails.code }}
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs6 text-left>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Promocode Discount
            </span>
          </v-flex>
          <v-flex xs6 text-right>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #2cb194"
            >
              - Rs.
              <span v-if="storage.promoCodeDiscount">
                {{ storage.promoCodeDiscount }}
              </span>
              <span v-else>0</span>
            </span>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap justify-center pt-2>
          <v-flex xs6 text-left>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Handling Charge
            </span>
          </v-flex>
          <v-flex xs6 text-right>
            <span
              style="font-family: poppinslight; font-size: 14px; color: #000000"
            >
              Rs.
              <span v-if="storage.shippingcharge">
                {{ storage.shippingcharge }}
              </span>
              <span v-else>0</span>
            </span>
          </v-flex>
        </v-layout> -->

        <v-layout wrap justify-center py-4>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pt-2>
          <v-flex xs6 text-left>
            <span
              style="
                font-family: poppinssemibold;
                font-size: 14px;
                color: #000000;
              "
            >
              TOTAL AMOUNT
            </span>
          </v-flex>
          <v-flex xs6 text-right>
            <span
              style="
                font-family: poppinssemibold;
                font-size: 14px;
                color: #000000;
              "
            >
              RS.
              <span v-if="storage.amount">
                {{ storage.amount }}
              </span>
              <span v-else>0</span>
            </span>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap justify-center py-4>
          <v-flex xs12>
            <v-btn :ripple="false" depressed block tile color="#FF2323">
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 12px;
                  color: #fff;
                  text-transform: none;
                "
              >
                <span> Continue </span>
              </span>
            </v-btn>
          </v-flex>
        </v-layout> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      deliverydate: new Date(),
      user: {
        deliveryaddress: [],
      },
      cart: {},
    };
  },
  methods: {},
};
</script>